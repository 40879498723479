import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Junio"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/junio"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/junio",
        title: "Efemérides - Junio | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – JUNIO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de junio de 1878: El coronel Gregorio Villegas, del ejército nacional,
      derrotó al comandante Silvestre Cano, en Chorrillos, provincia de Salta.
    </p>
    <p>
      1 de junio de 1914: Nació el doctor Julio A. Cintioni. Fue presidente del
      Círculo Médico de Salta. Fundador de A.L.P.I., filial Salta. Ministro de
      Asuntos Sociales y SAlud Pública de la provincia desde 1955 hasta 1956.
      Murió el 8 de marzo de 1957.
    </p>
    <p>
      1 de junio de 1948: Asumió el gobierno de la provincia de Salta, don
      Baudilio Emilio Espelta.
    </p>
    <p>
      1 de junio de 1950: Nació en Los Toldos, provincia de Salta, Angel G.
      Ramírez. Policía retirado que se dedicó a la política, resultando electo
      concejal y luego intendente de Los Toldos.
    </p>
    <p>
      1 de junio de 1951: Nació en Salta, Miguel Angel Cáseres. Profesor de
      historia en colegios secundarios de nuestra ciudad. Dirigente activo que
      aportó con su trabajo a la Biblioteca Popular Juan Carlos Dávalos, el club
      Atlético Pellegrini, entre otras entidades. Publicó en libros: “Anaqueles
      de la liberación”, “Raíces del Juan Calchaqui”, “Memoria” (poemario); y
      recientemente un libro de poemas, titulado “Sentimientos”. Fue Secretario
      de Seguridad Social de la Provincia, Presidente de CAMPE, Fundador del
      Colegio Juan Calchaqui.
    </p>
    <p>
      2 de junio de 1820: El comandante Luis Burela, del ejército de Güemes,
      derrotó a las tropas del brigadier Pedro Antonio de Olañeta, realista, en
      La Troja, provincia de Salta.
    </p>
    <p>
      2 de junio de 1821: El general Güemes, gobernador de Salta, al frente del
      ejército patriota, fue derrotado por los realistas, en Chamical, provincia
      de Salta.
    </p>
    <p>
      2 de junio de 1936: Nació en Córdoba Jorge Oscar Folloni. Hijo de
      italianos. Abogado. Se radicó en Salta en 1968. Casado con Marta Rosa
      López, con quien tiene 4 hijos. Ocupó importantes cargos en la provincia.
      En la actualidad es Diputado Nacional.
    </p>
    <p>
      2 de junio de 1955: Murió en Buenos Aires, Luis Linares. Nacido en Salta,
      abogado, diputado provincial, ministro de gobierno y gobernador de la
      provincia desde el 20 de febrero de 1907 hasta el 20 de febrero de 1910 y
      ministro de la Suprema Corte de Justicia.
    </p>
    <p>
      2 de Junio de 1955: Nació en Salta, Graciela Medina. Se inició como
      locutora en LR4 Radio Nacional Salta en 1986. Desde 1988 realiza tareas
      periodísticas y de locución en Canal 11 de Salta, donde forma parte del
      programa DESPERTAR junto a Martín Grande. Conduce también Panorama
      Gremial, antes del Noticiero Mundo en la Noticia.
    </p>
    <p>
      2 de junio de 1978:&nbsp;Murió en Salta Roberto Luis Patrón Costas. Nacido
      el 28 de enero de 1907. Explotó la Estancia “El Rey” (patrimonio de sus
      mayores). Promotor y fundador de la Sociedad Anónima Altos Hornos Güemes.
      Primer salteño que tienta la crías del visón. Presidente de la Sociedad
      Rural Salteña.
    </p>
    <p>
      3 de junio de 1889:&nbsp;Murió en La Plata Manuel Serapio Burela, hijo del
      famoso coronel don Luis Burela y doña Teresa Gauna. Nació en Chicoana
      alrededor de 1820. Prestó servicio a las órdenes del general Lamadrid. En
      1853 se registra su ascenso al grado de sargento mayor de Guardias
      Nacionales. En 1889 fue asignado a la Plana Mayor Inactiva.
    </p>
    <p>
      3 de junio de 1907: Nació en Coronel Pringles, provincia de Buenos Aires,
      José Argentino Di Giulio, quien fue maestro de Banda del Ejército
      Argentino y profesor de Armonía y Contrapunto de distintos Institutos del
      País y Uruguay. Compuso más de 1500 temas musicales, registradas en
      SADAIC, junto a Marcos Thames, Ariel Petrocceli, Isbelio Godoy, Los
      Hermanos Berrios y muchos otros. Autor del “Himno de Salta”, “Nombre de
      madre”, entre otros temas. Sus escritos fueron importantes, especialmente
      los relevamientos hechos en Jujuy entre 1940 y 1950.
    </p>
    <p>
      3 de junio de 1929: Nació en Salta Ramiro Arturo Peñalva, quien se
      desempeñó como periodista en diario El Tribuno y fue Director de Cultura
      de la provincia de Salta. Incursiona en las letras. Publicó un libro de
      poemas Activo dirigente de entidades culturales.
    </p>
    <p>
      3 de junio de 1958: Nació en Ituzaingó, provincia de Buenos Aires, Juan
      José Nuñez, quien se ordenó sacerdote de la orden franciscana. Fue párroco
      de Embarcación y de Tartagal, en el norte de la provincia. Actualmente es
      el Capellán Militar de la Guarnición Ejército Salta y Superior del
      Convento San Francisco de Salta.
    </p>
    <p>
      4 de junio de 1939: Nació en Salta, Narciso Ramón Gallo. Contador Público
      Nacional que fue Presidente del Colegio de Graduados en Ciencias
      Económicas de la Provincia de Salta, Vocal de la Mesa Directiva de la
      Federación Argentina de profesionales en Ciencias Económicas, Vice Decano
      de la Facultad de Ciencias Económicas Jurídicas y Sociales de la UNSa,
      Decano de la misma Facultad (Tres veces), Contador General de la provincia
      de Salta y Convencional Municipal Constituyente. Actualmente es el Rector
      de la Universidad Nacional de Salta.
    </p>
    <p>
      4 de junio de 1943: Nació en Salta, Fernando Lecouna de Prat. Prestigioso
      empresario agropecuario. Se desempeña, además como Gerente de Canal 11 de
      Salta.
    </p>
    <p>
      4 de junio de 1952: Asumió la gobernación de Salta, el doctor Ricardo
      Joaquín Durand.
    </p>
    <p>
      4 de junio de 1953: Nació Roberto Francisco Caro. Como político fue
      elegido concejal por el período 1985/1987 y después electo intendente de
      La Merced por el período 1987/1991. Fue Congresal por el departamento de
      Cerrillos de 1987 a 1991. Electo diputado por el mismo departamento para
      el período 1993/1997. Es Presidente de la Comisión Bicameral Examinadora
      de Obras y Autores Salteños y Presidente de la Comisión de Agricultura y
      Ganadería de la Cámara de Diputados.
    </p>
    <p>
      5 de junio de 1867: Nació en Falun, Suecia, Eric Boman. Este estudioso
      sueco llegó a Buenos Aires en 1889. Visitó el Chaco, permaneció 10 años en
      Catamarca, entregado a la docencia y al estudio de la pre y protohistoria
      del antiguo Tucumán. Viajó por la puna jujeña, exploró el Valle de Lerma,
      las Quebradas del Toro y las Cuevas en Salta y la región oriental de la
      Puna de Atacama, en Jujuy, regresando por Humahuaca. Publicó en París dos
      grandes volúmenes, en 1908.
    </p>
    <p>
      6 de junio de 1796: Se doctoró en Teología, el doctor José Benito de
      Alzérreca, en la Universidad de San Francisco Javier de Chuquisaca. Se
      doctoró también en Cánones. Luego se graduó de abogado. Fue uno de los
      promotores de la revolución boliviana del 25 de mayo de 1809, en
      Chuquisaca. De Chuquisaca, se fue a vivir a Tarija y luego a Salta. Llegó
      a ser Procurador General de la ciudad de Salta.
    </p>
    <p>
      6 de junio de 1857: Cuando renunció Dionisio Puch al cargo de gobernador,
      por razón de enfermedad (pérdida de la vista), la Legislatura reunida en
      sesión extraordinaria, nombró gobernador propietario a su vice-presidente
      1º, el ciudadano Martín Güemes y Puch, hijo primogénito del general Martín
      Miguel de Güemes.
    </p>
    <p>
      6 de junio de 1886: El concejo Municipal de Metán acordó donar la suma de
      doscientos pesos moneda nacional para los gastos que ocasione la
      inauguración de la estación del Ferrocarril Central Norte Argentino, hecho
      de lo más trascendentes de la historia de Metán.
    </p>
    <p>
      6 de junio de 1893: Murió en Jujuy, don Benjamín Villafañe. Una escuela en
      Peña Colorada, departamento de Orán, lleva su nombre como justiciero
      homenaje a su memoria, ya que su incansable labor, acercó beneficios para
      la región. Fue teniente gobernador de Orán.
    </p>
    <p>
      6 de junio de 1944: Nació en Coronel Solá, provincia de Salta, Dardo
      Verchán. Abogado que se desempeñó como asesor letrado de distintos
      municipios. Fue Convencional Constituyente en 1986. Diputado provincial de
      1987 a 1989, Interventor Area Asuntos Jurídicos del ex Banco Provincial de
      Salta. En la actualidad es Diputado Provincial por el período 1993/1997 y
      es Presidente del Comité Central del Movimiento Popular Salteño.
    </p>
    <p>
      6 de junio de 1962:&nbsp;Se designó Interventor Federal de Salta, a Julio
      Antonio Castellanos, luego reemplazado por el ingeniero Pedro Félix Remy
      Solá.
    </p>
    <p>
      7 de junio de 1820: El coronel Dionisio Puch, del ejército de Güemes,
      derrotó a los realistas, en La Troja, provincia de Salta.
    </p>
    <p>
      7 de junio de 1821: Güemes, se encontraba en la noche en su casa, de la
      actual calle Caseros 760, atendiendo asuntos de gobierno, cuando se enteró
      que fuerzas realistas habían ocupado la ciudad. De inmediato trató de
      abandonar la plaza. Montó a caballo seguido de su escolta y llegó a la
      actual calle Balcarce, por donde siguió hasta la hoy avenida Belgrano.
      Aquí lo esperaba un grupo realista que no pudo impedir que Güemes y su
      gente salvara el obstáculo y siguiera hacia el Este, pero una bala de las
      descargas efectuadas le penetró por la coxis, hiriéndolo mortalmente. La
      herida no impidió al prócer seguir a caballo en dirección al cuartel del
      Chamical, situado hacia el sudeste de la ciudad. Al llegar a las
      proximidades de este cuartel y a efecto de no ser localizado por el
      enemigo, Güemes fue conducido hasta una Cañada inaccesible para quien no
      conociera la zona, llamada Cañada de la Orqueta. Allí murió el 17 de junio
      pero no sin que antes jurara el oficial que tomó el mando de su fuerza,
      coronel Jorge Enrique Vidt, que seguiría combatiendo a los realistas hasta
      vencer. El cuerpo de Güemes recibió sepultura en la Capilla del Chamical,
      de donde al año siguiente fue conducido a la Antigua Catedral de Salta. En
      oportunidad de la construcción del actual templo mayor de los salteños,
      fue trasladado al mausoleo de la Familia Güemes, en el cementerio de la
      Santa Cruz. En 1918, al erigirse el Panteón de las Glorias del Norte, en
      la Catedral de Salta, fue solemnemente trasladado a este panteón, donde
      hoy es venerado.
    </p>
    <p>
      7 de junio de 1866:&nbsp;Falleció Magdalena Dámasa Güemes de Tejada, era
      hermana de Martín Miguel, se popularizó con el nombre de “Macacha” Güemes.
      Nació en Salta el 11 de diciembre de 1787. Sus padres fueron don Gabriel
      de Güemes y Montero y doña Magdalena Goyechea y la Corte. Sus nombres
      completos era Magdalena Dámasa, casose muy joven, casi una niña, pues
      tenía apenas 15 años, el 14 de octubre de 1803 con don Román de Tejada
      quien contribuyó con su persona y bienes.
    </p>
    <p>
      7 de junio de 1936: Murió en Buenos Aires, Dolores Lola Mora. De padres
      tucumanos, nació en La Candelaria, provincia de Salta, en 1866. Hizo los
      estudios primarios en Tucumán. En 1887 comenzó a estudiar dibujo y pintura
      con el profesor Santiago Falcucci. Becada por el gobernador de Tucumán,
      doctor Benjamín Aráoz, se trasladó a Buenos Aires. En 1898 el gobierno
      nacional el otorgó una beca para que se perfeccione en Roma. Triunfó en el
      Viejo Mundo. Fue artista oficial de la casa de Saboya, protegida de la
      Reina Margarita de Italia, del Kaiser Guillermo II de Alemania y del Papa
      León XIII. Fue distinguida por los más destacados círculos artísticos de
      Europa. Sus obras más notables son: Fuente de las Nereidas (en la avenida
      Costanera); los grupos escultóricos para el Frente del Congreso de la
      Nación, los Bajos relieves de la Casa de la Independencia, la estatua de
      la Libertad, (en la plaza Independencia de Tucumán), el monumento a Juan
      Bautista Alberdi (en Tucumán), estatua al general Alvear (Corrientes),
      estatua de mármol blanco de carrera al doctor Facundo de Zuviría (Salta),
      y muchas otras.
    </p>
    <p>
      7 de junio de 1937: El Arzobispo de Salta, Monseñor Roberto José Tavella,
      dictó un decreto fundando el Instituto de San Felipe y Santiago de
      Estudios Históricos de Salta, contando como miembros fundadores a: general
      Ricardo Solá, doctor Carlos Serrey, doctor Atilio Cornejo, ingeniero
      Rafael P. Sosa, doctor David Saravia Castro, doctor Julio Torino, doctor
      Arturo Torino y doctor Santiago Fleming.
    </p>
    <p>
      7 de junio de 1940: Se presentó en la legislatura el proyecto de los
      senadores Patrón Carlos Costas y Serrey para la construcción del dique
      “Cabra Corral” o “Corral Quemado”.
    </p>
    <p>
      8 de junio de 1774: Salió del Fuerte de San Bernardo, sobre el Río del
      Valle, Salta, la columna expedicionaria a cuyo frente estaba Gerónimo
      Matorras con 378 hombres rumbo al Chaco Gualamba.
    </p>
    <p>
      8 de junio de 1820:&nbsp;El general Juan Ramírez y Orozco y sus fuerzas
      invasoras evacuaron la ciudad de Salta y retrocedieron hasta su cuartel
      genera de Tupiza, adonde llegarán el 30 de junio. Güemes y sus gauchos
      hostigaron al ejército español durante la retirada.
    </p>
    <p>
      8 de junio de 1820: San Martín nombró a Martín Miguel de Güemes, como
      general en jefe del ejército que mandaba el general Jerónimo Valdés, en La
      Pedrera, Salta.
    </p>
    <p>
      8 de junio de 1820: El capitán Alejandro Burela, del ejército de Güemes,
      derrotó a los realistas del ejército que mandaba el general Jerónimo
      Valdés, en La Pedrera, Salta.
    </p>
    <p>
      8 de junio de 1951: Nació en Salta, Sergio Antonio Teseyra. Poeta que
      publicó “El deudo de los pájaros” y realiza actividades culturales en
      Salta.
    </p>
    <p>
      9 de junio de 1921: Nació en El Galpón, provincia de Salta, Ricardo Munir
      Falú. Abogado y periodista. Ejerció el periodismo en Nueva Epoca y la
      provincia de Salta, y en El Orden en Tucumán. Fue director de diario El
      Tribuno de 1949 a 1955. Delegado de Trabajo y previsión en 1946,
      secretario de Trabajo y Previsión en Jujuy y Delegado de Trabajo y
      Previsión en Tucumán. Subsecretario de gobierno en el primer gobierno de
      Ricardo Joaquín Durand. Fue apoderado y Presidente del Partido
      Justicialista de Salta. Diputado Nacional de 1973 a 1976. Fue Presidente
      de la Comisión de Energía y Combustible de la Cámara de Diputados de la
      Nación. Delegado a la Conferencia interparlamentaria Mundial celebrada en
      Tokio en 1975. Fue integrante de misiones oficiales a Libia, paises del
      Este Europeo, China y Corea. Actualmente es Juez de la Cámara Federal de
      Apelaciones, en Salta.
    </p>
    <p>
      9 de junio de 1928: Nació en Salta, Antonio Yutronich. Destacado artista
      plástico. Reconocido a nivel nacional. Sus obras son poseídas por
      coleccionistas de Europa y América. Ha obtenido numerosos premios, entre
      los que se destaca el Premio Comisión Nacional de Cultura en 1949. Gran
      conocedor de la historia del arte y de las distintas escuelas de pintura.
    </p>
    <p>
      9 de junio de 1952: Nació en General Güemes, Salta, Guillermo Enrique
      Jordán. Fue Presidente del Club Atlético Central Norte de General Güemes.
      Congresal Provincial del Partido Justicialista y activo participante de
      las actividades culturales y deportivas con la niñez del departamento de
      General Güemes. Actualmente es Diputado Provincial por ese departamento y
      Presidente de la Comisión Especial de Industria y Comercio de la Cámara de
      Diputados de la provincia de Salta.
    </p>
    <p>
      9 de junio de 1992: Se inauguró FM. Santa Rita de Rosario de Lerma, que se
      ubica en el 105.5 Mhz del dial. Se desempeñan como directores Agustín
      Montoya y Graciela Girón de Montoya.
    </p>
    <p>
      10 de junio de 1814: Gauchos salteños, del ejército de Güemes, derrotarón
      a los realistas, en Sumalao, provincia de Salta.
    </p>
    <p>
      10 de junio de 1859: Martín Güemes y Puch, hijo primogénito del héroe,
      concluyó su período completo de mandato como gobernador de Salta,
      recibiendo la gratitud y el reconocimiento del pueblo salteño.
    </p>
    <p>
      10 de junio de 1942&nbsp;: Comenzó a construirse el mástil de la Plaza San
      Martín de Metán, en la provincia de Salta. La bandera para el mismo fue
      donada por la colectividad Sirio-Libanesa.
    </p>
    <p>
      10 de junio de 1943: Nació en Jujuy, Gregorio Quipildor quien desde niño
      fue trasladado a Salta por sus padres. Pertenece a una familia campesina.
      Su padre fue cantor de raza y quien le transmitió la sabiduría de la
      canción folklórica. Sus condiciones naturales lo impulsan a iniciar una
      carrera profesional. En 1969, se radicó en Buenos Aires y al poco tiempo
      realiza su primera gira por Europa. En 1974, Ariel Ramírez lo incorporó
      como solista para su Misa Criolla. Su nombre artístico es Zamba Quipildor,
      quien recorre el mundo con música argentina y lleva realizadas numerosas
      grabaciones. Gonza de gran popularidad en sus país, este artista que actuó
      en más de cien países.
    </p>
    <p>
      10 de junio de 1961: Juan XXIII designó primer obispo de la diócesis de la
      Nueva Orán a Fray Francisco de la Cruz Muguerza, religioso franciscano de
      la orden de los frailes menores y definidor de esta orden en la República
      Argentina. Había nacido en Río Cuarto provincia de Córdoba el 23 de agosto
      de 1921. Ingresó a la orden franciscana en 1934, en el convento de Moreno,
      provincia de Buenos Aires. Fue ordenado sacerdote el 21 de diciembre de
      1946. Se doctoró en filosofía en Roma. Fue consagrado obispo el 24 de
      setiembre de 1961, en San Antonio de Padua, Buenos Aires por el arzobispo
      de Salta, monseñor Tavella y pronunció palabras el gobernador Bernardino
      Biella. Falleció a los 47 años, en Buenos Aires, el 30 de abril de 1969.
      Enterrado en la Catedral de Orán.
    </p>
    <p>
      10 de junio de 1962: Se fundó la Agrupación Gauchos de Güemes Rodeo
      Antenor Sánchez de Campo Quijano, en la provincia de Salta, logrando la
      Personería Jurídica el 18 de noviembre de 1987. La sede social está en la
      calle Buenos Aires, esquina Avenida Chile de Campo Quijano.
    </p>
    <p>
      10 de junio de 1991: Se fundó la Biblioteca Popular Martín Miguel de
      Güemes, en Joaquín V. González, Salta. Entre los fundadores Jorge Pacheco,
      Julia Fernández y Alicia Molina. Entre sus objetivos la difusión cultural,
      talleres de lectura y escritura. Los responsables de la actual comisión
      además de los fundadores, Rubén Kairuz y Hugo Medina. Colabora en
      distintas actividades socio-culturales de la comunidad, brinda cursos de
      perfeccionamiento a docentes y charlas para adolescentes.
    </p>
    <p>
      11 de junio de 1814: El teniente Briguido Arias, del ejército de Güemes,
      derrotó a los realistas, en El Carril, provincia de Salta.
    </p>
    <p>
      11 de junio de 1838: El general Raña, boliviano, perteneciente al ejército
      del mariscal Andrés de Santa Cruz, derrotó a las tropas argentinas
      mandadas por el general Alejandro Heredia, en Iruya, provincia de Salta.
    </p>
    <p>
      11 de junio de 1891: Nació Ernesto M. Aráoz en Salta. En 1919 egresó de la
      Universidad de Buenos Aires, con el doctorado en jurisprudencia. En 1914
      editó junto a otros la revista “Ideas”. Fue diputado provincial, en 1932
      diputado nacional por Salta. Fue vicepresidente del senado provincial.
      Vicegobernador de Salta desde el 1 de mayo de 1941 hasta el 1 de diciembre
      de 1941. Fue gobernador de Salta hasta el 15 de junio de 1943 en reemplazo
      del fallecido doctor Abraham Cornejo, Los títulos de sus libros son
      “Páginas de juventud” (1914), “Salta en las épocas de la Anarquía” (1923),
      “El alma legendaria de Salta” (1936), “Palabras en el Parlamento” (1938),
      “Al margen del pasado” (1944), “El diablito del Cabildo” (1º ed. 1946) –
      (2º ed. 1969), “Vida y obra del doctor Patrón Costas” (1966). Publicó
      folletos y colaboró con revistas de Salta y Capital Federal. Falleció en
      Salta el 4 de marzo de 1971 a los 80 años.
    </p>
    <p>
      11 de junio de 1921: Nació Tartagal, Salta, Alberto Abraham. Comerciante y
      empresario. Sus condiciones personales lo distinguieron como un dirigente
      indiscutible ya que fue elegido ocho veces como intendente de Tartagal,
      cargo que ejerce por veinticinco años.
    </p>
    <p>
      11 de junio de 1956: Nació en Salta, Carlos Luis Correa Zalazar. Se
      recibió en Córdoba de médico en 1980. Docente en la Universidad de
      Córdoba. Miembro titular de la Sociedad de Medicina Materna. Miembro
      titular de la Sociedad de Cardiología de Salta. Publicó tratados y
      estudios de su especialidad.
    </p>
    <p>
      11 de junio de 1980: Se fundó en la ciudad de Salta, la Clínica San Pablo.
    </p>
    <p>
      12 de junio de 1814:&nbsp;El comandante Alejandro Burela, del ejército de
      Güemes, derrotó a los realistas, en Gaona, provincia de Salta.
    </p>
    <p>
      12 de junio de 1814: El capitán Martín Miguel de Güemes, derrotó a los
      realistas en La Merced, provincia de Salta.
    </p>
    <p>
      12 de junio de 1949: Nació en Salta, Luis Antonio Escribas. Periodista y
      poeta. Publicó en 1973 a la plaqueta Carne temprana; Los Pasos del
      Silencio (1977 – Primer premio de poetas inéditos organizado por la
      Dirección General de Cultura de Salta en 1975); Coplas del Carnaval (en
      colaboración en 1982). Figura en “Selección Poética Hispanoamericana”
      (1979) y “Cuatro Siglos de Literatura Salteña” (de Walter Adet – 1981). En
      1992 publicó el libro de poemas “Requiem al Olvido”, que mereció la
      Mención de Honor en el Concurso de poesías para autores éditos y el tercer
      premio del concurso Regional de Poesías “Hugo F. Rivella”, en Rosario de
      la Frontera en 1990.
    </p>
    <p>
      12 de junio de 1954: Se abrió en Salta, la filial de ALPI (Asociación para
      la lucha contra la parálisis infantil). Su primera presidente fue María
      Antonieta Day. En Buenos Aires, se fundó el 17 de diciembre de 1943,
      siendo su presidente la señorita Marta Ezcurra. El asesor de la primera
      comisión salteña fue el doctor Miguel Ramos. En noviembre de 1956, la
      filial salteña de ALPI pudo contar con edificio propio al concretrarse la
      compra del inmueble de calle Zuviría 627, actual sede, gracias al apoyo
      oficial. En 40 años de vida esta institución fue presidida por las
      siguientes personas: Esther Binda, Olga M. de Nuñez Vela, Celia Cánepa de
      Barrantes y Clara Stella Chavarría quien preside ALPI filial Salta desde
      1970, vale decir hace más de 25 años.
    </p>
    <p>
      12 de junio de 1962:&nbsp;Se fundó en la ciudad de Tartagal, provincia de
      Salta, la Clínica San Antonio.
    </p>
    <p>
      13 de junio de 1749: Nació en Altafulla, a dos leguas de Tarragona,
      España, el Fray Antonio Comajuncosa. Perteneció a la orden Franciscana.
      Trabajó en Orán. Escribió testimonios históricos. Murió en Tarija,
      Bolivia, el 2 de octubre de 1814.
    </p>
    <p>
      13 de junio de 1871:&nbsp;Asumió como gobernador de Salta, el coronel
      Delfín Leguizamón, con 28 años de edad.
    </p>
    <p>
      13 de junio de 1873: Asumió como gobernador de Salta, el doctor Juan Pablo
      Saravia.
    </p>
    <p>
      13 de junio de 1873: Falleció en Salta, Victorino Solá Tineo. Había nacido
      en Salta en 1789. En 1826, fue desigando gobernador delegado por el
      general Arenales. Perteneció a la Liga del Norte.
    </p>
    <p>
      13 de junio de 1875: Asumió como gobernador de Salta, don Miguel Francisco
      de Aráoz.
    </p>
    <p>
      13 de junio de 1886: Se inauguró la Estación de Ferrocarril de Metán, en
      la provincia de Salta, que dió origen al pueblo nuevo, que en definitiva
      es el actual.
    </p>
    <p>
      13 de junio de 1963: Inició su transmisión LW2 Radio Emisora Tartagal. Al
      principio fue dirigida por Néstor Santos Martínez Gil y desde 1978 está
      bajo la conducción de Olimpia Pérez del Busto de Oller, Vicente Oller
      Pardo y Carlos Alberto Martínez.
    </p>
    <p>
      14 de junio de 1909: Nació en Salta José Octavio Cornejo Saravia. Egresó
      del Colegio Militar de la Nación con el grado de sub-teniente. Falleció en
      1968.
    </p>
    <p>
      14 de junio de 1930: Nació en Rosario de la Frontera, Salta, Rodolfo
      Aniceto Fernández. Productor agropecuario. Fue Concejal, Intendente,
      Diputado Provincial, Intendente, Diputado Provincial, Senador Provincial,
      Intendente de la Capital Salteña, Ministro de Gobierno, Ministro de
      Educación y Justicia, Ministro de Bienestar Social y Ministro de Salud
      Pública. En la actualidad es el Interventor del Hotel Termas de Rosario de
      la Frontera.
    </p>
    <p>
      15 de junio de 1659:&nbsp;En Casalac, Alonso de mercado y Villacorta,
      gobernador de Tucumán, derrotó a los indígenas revolucionarios mandados
      por el impostor Pedro Bohorquez, en los Valles Calchaquíes y los devovió a
      la obediencia.
    </p>
    <p>
      15 de junio de 1816: El director Supremo Juan Martín de Pueyrredón se
      entrevistó nuevamente con el gobernador-intendente de Salta, coronel
      Martín Miguel de Güemes, en Cobos, provincia de Salta.
    </p>
    <p>
      15 de junio de 1818: Nació en Horcones, Salta, Juana Manuela Gorriti. Su
      juventud transcurrió en Bolivia. Allí, contrajo matrimonio en 1833 con el
      oficial militar Manuel Isidoro Belzú, quien llegó a ser dos veces
      presidente de Bolivia. Su vocación literaria se manifiesta en Bolivia.
      Escribía para diarios y revistas de Bolivia, Lima (Perú), Chile, Colombia,
      Venezuela, Argentina, España y Francia. Su primer novela fue La Quena
      (1845). Se estableció en Buenos Aires en 1874. Algunas de sus obras: El
      guante negro, El album de una peregrina, La hija del mazorquero, Un drama
      en el Adriático, El lecho nupcial, La duquesa, Biografía del general
      Güemes, La duquesa de Alba, La novia del muerto, Biografía del general
      Dionisio Puch, Sueños y realidades, La tierra natal, El pozo de Yocci, El
      tesoro del Inca, El angel caído. Murió en Buenos Aires el 6 de noviembre
      de 1892.
    </p>
    <p>
      16 de junio de 1890: Nació en Rivadavia, Salta, Luis Enrique Cerda
      Salvatierra, incursionó en los cultivos de algodón, al comprobar el
      rendimiento llevó a muchos salteños a instalarse en la naciente ciudad de
      Presidencia Roque Sáenz Peña. Cerda fue uno de sus primeros vecinos.
      Secretario de la Asociación de Fomento de la Enseñanza. Fundador y gerente
      de la Cámara de Comercio. Fundador del club social Presidencia Roque Sáenz
      Peña y del centro democrático Español. Fundó el periódico “La Unión ” –
      más adelante “Chaco” – y finalmente Director de “La Voz de Sáez Peña”.
      Juez de Paz en Charatas. De nuevo en su provincia, fue comisario de
      policía en Rivadavia. Murió en Córdoba a los 86 años.
    </p>
    <p>
      16 de junio de 1918: Se bendijo la nueva Capilla de San Antonio de Orán.
      Los frailes franciscanos lograban su nueva Capilla en Orán.
    </p>
    <p>
      17 de junio de 1796: Nació en Salta, Victorino Solá Tineo. Fue quien
      gestionó ante el gobierno de Rivadavia la compra de la imprenta llamada
      “de los niños Expósitos”. Logró que el gobierno nacional la donara a
      Salta. Con ellas se editaba “La Revista de Salta”. Plantó junto a su
      hermano Manuel, moreras para la explotación del gusano de seda. Murió en
      Salta el 13 de junio de 1873.
    </p>
    <p>
      17 de junio de 1798: Nació en Salta, Manuel Solá Tineo. Se alistó joven en
      el ejército de Güemes. Fue uno de los principales organizadores de la Liga
      del Norte. Fue dos veces gobernador de Salta. Murió en Salta el 23 de
      febrero de 1867. Organizó una expedición de esta vía fluvial, demostrando
      a su regreso, en 1826, las ventajas de su empleo. Llegó al grao de General
      del Ejército. Incursionó en el periodismo y dejó un “Catecismo político de
      los niños”.
    </p>
    <p>
      17 de junio de 1814:&nbsp;El capitán de la Zerda, del ejército de Güemes,
      derroto a las tropas realistas, en El Pongo, provincia de Salta.
    </p>
    <p>
      17 de junio de 1821: Falleció Martín Miguel de Güemes. Gran figura de la
      Independencia Argentina y Americana. Todos los años los gauchos salteños
      recuerdan al héroe con sentidas ceremonias. La historia le debe el gran
      lugar que le corresponde.
    </p>
    <p>
      17 de junio de 1910: Nació en Salta Augusto Raúl Cortazar. Profesor en
      Letras, Abogado, bibliotecario y doctor en Filosofía y Letras. Director
      del Fondo Nacional de las Artes. Falleció en la Capital Federal el 16 de
      setiembre de 1974. Publicó: “El Carnaval en el folklore Calchaquí”;
      “Confluencias culturales en el folklore argentino”; “Ecología folklórica”;
      “La Fiesta Patronal de Nuestra Señora de la Candelaria en Molinos
      (Salta)”; “El folklore y su estudio integral”; “Folklore argentino”, entre
      otros títulos.
    </p>
    <p>
      17 de junio de 1941: Nació en Salta, Carlos Alberto Ceballos, quien fue el
      primer mascota de la Agrupación Tradicionalista Gauchos de Güemes. A los
      tres años empezó su carrera artística como zapateador y luego por muchos
      años fue bailarín de distintos espectáculos. Como autor integró “Los
      quebradeños”, “Las Voces del Huayra”, “Los de Salta”. Difundió la platería
      de Salta por el país y por España, Japón y otros países.
    </p>
    <p>
      17 de junio de 1960: Murió en Salta, David Michel Torino. Fundó con sus
      hermanos la firma Michel Torino Hnos. Director-Propietario del diario El
      Intransigente. Presidente de la Bolsa de Comercio de Salta. Presidente del
      club Gimnasia y Tiro.
    </p>
    <p>
      18 de junio de 1814: El general Martín Miguel de Güemes al frente de sus
      gauchos derroto al brigadier Pedro Antonio de Olañeta al frente del
      ejército realista, en Fuerte del Río del Valle, provincia de Salta.
    </p>
    <p>
      18 de junio de 1946: Se fundó el Club de Pesca Salta. La primera Comisión
      directiva se eligió el 3 de julio de ese año, fue presidida por Marcelino
      Benavente; vicepresidente, Angel Antonio Spaventa; Secretario, Mario Di
      Primo; prosecretario, Víctor Fernández; tesorero, Luis Castellani;
      Vocales: Eduardo Sachetti, Antonio Castellani, Narciso Aguirre y Félix
      Pepernac. En la actualidad tiene su sede propia en la calle Aniceto
      Latorre Nº 285 de nuestra ciudad. Sus autoridades actuales son:
      Presidente, Miguel A. Durán; Vicepresidente, Néstor A. Gallo y Tesorero,
      Néstor Durán.
    </p>
    <p>
      18 de junio de 1971: Nació en Orán, Carmen Adelaida Domínguez. Ganó el
      tercer premio del concurso literario anual de Orán con Duendes de Orán.
      Incluída en antología de Orán.&nbsp;
      <br />
      18 de junio de 1995: Falleció el sacerdote salesiano Angel Aimonetto,
      quien trabajo en la formación de varias generaciones de jóvenes salteños.
    </p>
    <p>
      19 de junio de 1810: El cabildo de Salta, se adhirió a la Junta de
      gobierno formada en Buenos Aires, llamada Primera Junta, siendo la primer
      provincia del actual territorio del noroeste argentino, que la reconoció.
    </p>
    <p>
      19 de junio de 1817: El coronel Manuel Puch, del ejército de Güemes,
      derrotó al brigadier Pedro Antonio de Olañeta al frente de las tropas
      realistas en Cuesta de la Pedrera, provincia de Salta.
    </p>
    <p>
      19 de junio de 1931: Nació en Salta, Juan Carlos Moreno. Fue integrante
      del conjunto Folklórico Los Fronterizos, desde 1954. Con este conjunto
      recorrió el mundo, llevando el mensaje de la música y la poesía de Salta.
    </p>
    <p>
      20 de junio de 1833: Nació en Salta, Federico Ibarguren. Estudió abogacia
      en Montevideo y expuso su tesis doctoral con el padrinazgo del doctor
      Facundo de Zuviría. Fue presidente del Superior Tribunal de Justicia en
      1861. Ministro de Gobierno (1869-71) circunstancias en que redactó el 1º
      Código de Procedimientos Judiciales en Salta. Fue docente en el Colegio
      San José e integró el primer equipo al ser oficializado el Colegio
      Nacional. Desempeñando funciones en el Poder Judicial de Santa Fe, le tocó
      asumir el cargo gubernativo en 1867 como consecuencia del derrocamiento de
      las autoridades constituidas. Senador Nacional por Salta en 1871, en 1874
      Juez Federal – Ministro de la Corte Suprema de Justicia de la Nación.
      Murió en Buenos Aires, el 19 de noviembre de 1890.
    </p>
    <p>
      20 de junio de 1948:&nbsp;Se fundó el Club de Fútbol Correos y
      Telecomunicaciones en Metán, provincia de Salta. Esta entidad, que ya
      desapareció, estaba afiliada a la Liga Metanense de Fútbol.
    </p>
    <p>
      20 de junio de 1965: Se fundó en Salta, el Hogar Instituto de
      Rehabilitación del Paralítico Cerebral (HIRPACE), por iniciativa de Nelly
      Barbarán Alvarado y el doctor Miguel Ramos. Ahora lleva el nombre de Nelly
      Barbarán Alvarado en homenaje a su fundadora.
    </p>
    <p>
      21 de junio de 1814: Las tropas de Güemes, derrrotaron a los realistas
      mandados por el coronel Marquiegui, en Pitos, provincia de Salta.
    </p>
    <p>
      21 de junio de 1947: Nació en Salta, Juan Mariano Gerardo Solá. Realizó
      sus estudios primarios en la Escuela Julio Argentino Roca y en 1964 se
      recibió de Maestro Nacional de Escuela. En 1969 ingresa como Auxiliar al
      Banco Provincial de Salta, en la Sucursal Tartagal. En esa entidad
      bancaria realizó una excelente carrera llegando a ser el Gerente General
      en 1985 y Presidente en 1987 con retención del cargo de la función de
      Gerente General. Realizó misiones especiales en Bolivia, España, Italia.
      Fue delegado general de la gremial interna del Banco Provincial en 1974;
      Presidente del XX Congreso Anual de Gerentes Generales de Bancos oficiales
      de Provincias (ABAPRA); Presidente del Comité Permanente de ABAPRA;
      Presidente de ASEMBAL (Asociación de Empleados de Banco Provincial de
      Salta) entidad para la que logró importantes conquistas. Realizó Curso de
      espacialización sobre distintos temas bancarios. Trabajó para el Banco BiD
      entre 1994 y 1995. Se desempeña en la AFJP Siembra y ocupó la Gerencia
      Regional de la AFJP Prorenta y Profuturo.
    </p>
    <p>
      21 de junio de 1994: Se realizó un acto protocolar en el Museo de la
      ciudad Casa de Hernández, donde el Consejo Deliberante de la ciudad de
      Salta, declaró ciudadano Ilustre al artista plástico Neri Cambronero. El
      artista inauguró en ese momento una muestra retrospectiva de sus obras.
      Hizo uso de la palabra el Intendente Municipal, don Víctor Abelardo
      Montoya y el poeta Miguel Angel Pérez expresó una semblanza del artista.
    </p>
    <p>
      22 de junio de 1814:&nbsp;El teniente Brígido Arias, del ejército de
      Güemes, derrotó a los realistas, en Pasaje, provincia de Salta.
    </p>
    <p>
      22 de junio de 1820: Falleció Juan Antonio Rojas. Se incorporó a las
      fuerzas patriotas. Fue uno de los mejores oficiales que tuvo Güemes. El 3
      de junio de 1820 resultó herido de gravedad cuando luchaba contra las
      tropas del general Valdés (a) “El Barbarucho”. Fue trasladado por sus
      gauchos a Cerrillos donde falleció.
    </p>
    <p>
      22 de junio de 1872: Falleció en su ciudad natal, a los 80 años, Rudecindo
      Alvarado. Nació el 1º de marzo de 1792, hijo del español don Juan
      Francisco de Toledo y Pimentel de Alba, poseedora de Blasón Nobiliario
      Hispánico. El 21 de abril de 1831 fue elegido gobernador de su provincia.
      Sus cenizas reposan en el Panteón de las Glorias del Norte en el interior
      de la catedral Metropolitana de la ciudad de Salta.
    </p>
    <p>
      22 de junio de 1916: Nació en Buenos Aires, Esdras Luis Gianella. Profesor
      Superior de Bellas Artes, escultor y orfebre. Fue profesor de dibujo en la
      Escuela Normal, Colegio Nacional y Bachillerato Humanístico. Profesor de
      escultura de la Escuela de Bellas Artes de Salta, en la que llegó a ser
      vicerector y luego rector. Fue el ejecutor del portal de entrada al
      Ingenio El Tabacal, del Busto a Corina Lona y de la estatua a Lona. Logró
      en 1943 el premio al Primer Mejor Conjunto de Joyería. Premiado en salones
      provinciales y en salones nacionales en el Salón Nacional de Bellas Artes.
      Actualmente es el presidente de Mercado Artesanal de Villa San Lorenzo.
    </p>
    <p>
      22 de junio de 1922: Nació en Salta, Alejandro Gauffin. Estudió en el
      colegio nacional y en el bachillerato Humanista de Salta. Fue ministro de
      gobierno en Jujuy. Falleció trágicamente en la ruta que une Salta con
      Jujuy, el 6 de junio de 1967.
    </p>
    <p>
      22 de junio de 1970:&nbsp;El Coronel Hernán J. Risso Patrón, jefe de la
      Guarnición Militar Salta, se hizo cargo interinamente del gobierno de la
      provincia de Salta, hasta el 14 de agosto, día que asumió el nuevo
      Interventor Federal, Coronel (RE) Raúl Pablo Aguirre Molina.
    </p>
    <p>
      23 de junio de 1912:&nbsp;Se da permiso a los señores Marcelino Sierra,
      Germán Alemán, Gotling, Gerardo López, Isidoro Badín y Eduardo Escudero
      (hijo), “para colocar postes en el camino y calles del municipio de Metán,
      para una línea de teléfono particular”. Esa fue la primera línea
      Telefónica de Metán y partía desde La Aguadita, siguiendo por Conchas y
      San José, hasta la Estación Metán.
    </p>
    <p>
      23 de junio de 1984:&nbsp;Se fundó la Liga Municipal de Fútbol San Lorenzo
      – La Caldera, que abarca los municipios de Vaqueros, La Caldera y San
      Lorenzo. La integran 18 clubes actualmente.
    </p>
    <p>
      24 de junio de 1820: El coronel Pedro José Zabala, del ejército de Güemes,
      derrotó a los realistas mandados por el brigadier Juan Ramírez de Orozco,
      en Cerrillos, provincia de Salta.&nbsp;
      <br />
      24 de junio de 1924: Nació Francisco Benedicto. Abogado que fue Magistrado
      del Poder Judicial de la Provincia de Salta. Es coautor del Código de
      procedimientos Penales, que instituyó la justicia oral y pública.
    </p>
    <p>
      24 de junio de 1928: Se fundó la Parroquia La Purísima de Tartagal, por el
      Comisario de las Misiones Franciscanas de Salta, sacerdote Gabriel
      Tommassini.
    </p>
    <p>
      24 de junio de 1948: Se creó el Parque Nacionel El Rey, para protección de
      la naturaleza casi virgen del lugar. Tiene 44.162 hectáreas, en el
      departamento de Anta, a 190 km. de la ciudad de Salta.
    </p>
    <p>
      24 de junio de 1987: Falleció en Buenos Aires, el poeta Hugo Alarcón.
      Hombre de radio y de escenarios, siendo un brillante animador. Fue autor
      de la letra de muchas canciones populares argentinas, que interpretaron
      los mejores artistas de nuestra música folklórica. Se publicó después de
      su muerte. “Los últimos serán los primeros”. Con Antonio Vilariño y Martín
      Adolfo Borelli, publicó una plaqueta de poemas.
    </p>
    <p>
      25 de junio de 1942:&nbsp;Por iniciativa del Arzobispo de Salta, Monseñor
      doctor Roberto José Tavella, el poder Ejecutivo Nacional oficializó el
      primer congreso de la cultura hispanoamericana, realizado en Salta.
    </p>
    <p>
      26 de junio de 1814: Martín Miguel de Güemes, al frente de sus gauchos,
      derrotó al coronel realista Marquiegui, en Anta, provincia de Salta.
    </p>
    <p>
      27 de junio de 1795: Se estableció el Cabildo de Orán, la ciudad del
      Norte. Se nombró al alférez real Diego Pueyrredón en el cargo de primer
      regidor, quien de su peculio amuebló la sala capitular.
    </p>
    <p>
      27 de junio de 1796: Los indios Tobas del Chaco Gualamba y otras tribus,
      se aprestaban a invadir los fuertes y haciendas. Esto conmocionó a la
      capital de la gobernación-intendencia de Salta.
    </p>
    <p>
      28 de junio de 1820:&nbsp;El coronel Juan Antonio Rojas, del ejército de
      Güemes, derrotó a los realistas mandados por el general José Canterac, en
      Cerrillos, provincia de Salta.
    </p>
    <p>
      28 de junio de 1942: Nació Ercilia Navamuel. Profesora de Historia,
      investigadora del Museo de Antroplogía. Tiene a su cargo la cátedra de
      historia de Salta, en el instituto Superior del Profesorado de Salta.
      Colabora en publicaciones especializadas.
    </p>
    <p>
      29 de junio de 1814: Los gauchos salteños del ejército de Güemes,
      volvieron a derrocar al coronel Guillermo Marquiegui, realista, en Santa
      Victoria, provincia de Salta.
    </p>
    <p>
      29 de junio de 1952: Nació en Salta, José Antonio Solá Torino. Abogado y
      docente. Fue Secretario de Gobierno y Justicia y Ministro de Biniestar
      Social. Jefe de Policía de la provincia. Profesor de Derecho Procesal
      Civil en la Universidad Católica de Salta. Fue candidato a vicegobernador,
      acompañando en la fórmula a Don Roberto Romero. En la actualidad se
      desempeña como Juez de Cámara del Tribunal Federal de Salta.
    </p>
    <p>
      29 de junio de 1991: Se creó en Metán. “Caballeros del Señor y de la
      Virgen del Milagro”, con el objetivo de promover y colaborar en los
      trabajos relacionados a las celebraciones de los cultos en honor del Señor
      y la Virgen del Milagro.
    </p>
    <p>
      30 de junio de 1783: Nació en Salta, Juan Ramón Boedo, hermano de Mariano
      Boedo. Actuó en las filas del ejército patriota junto al general Belgrano.
      Fue ascendido al grado de capitán. Cruzó Los Andes a las órdenes del
      coronel Las Heras. Murió el 5 de abril de 1818 (siendo comandante del
      Tercer Batallón de Infantería).
    </p>
    <p>
      30 de junio de 1931: Se hizo cargo del gobierno de Salta, el nuevo
      Interventor, doctor Raymundo Meabe.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
